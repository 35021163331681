
export const domain = {
  en: process.env.VUE_APP_DOMAIN_BASIC,
  fr: process.env.VUE_APP_DOMAIN_FR,
  es: process.env.VUE_APP_DOMAIN_ES,
  pt: process.env.VUE_APP_DOMAIN_PT,
  ja: process.env.VUE_APP_DOMAIN_JA,
};
const config = {
  version: process.env.VUE_APP_VERSION,
  packageName: process.env.VUE_APP_PACKAGE_NAME,
  mediaCDN: process.env.VUE_APP_MEDIA_CDN,
  googleSignInClientId: process.env.VUE_APP_GOOGLE_SIGN_IN_CLIENT_ID,
  kodepay: {
    applicationID: process.env.VUE_APP_KODE_PAY_APPLICATION_ID,
    clientID: process.env.VUE_APP_KODE_PAY_CLIENT_ID,
  },
  firebaseConfig: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  domain,
  lang: process.env.VUE_APP_LANG,
};

export const ENV = {
  get currentConfig () {
    return config;
  },
  get isDev () {
    return process.env.VUE_NODE_ENV == 'dev';
  },
  get isUat () {
    return process.env.VUE_NODE_ENV == 'uat' || process.env.VUE_NODE_ENV == 'uat2';
  },
  get isProd () {
    return !this.isDev && !this.isUat;
  },
};
